import React from "react";

export class Proyecto extends React.Component {
  constructor(props) {
    super(props);
    this.state = { descripcion: "" };
  }
  componentDidMount() {
    const xhr = new XMLHttpRequest();
    const url = `https://congresoapi.herokuapp.com/proyectos/12195`;

    xhr.open("GET", url);
    xhr.send();
    xhr.onload = () => {
      this.setState({ descripcion: proyectoToObject(xhr.response) });
      console.log(this.state);
    };
  }
  render() {
    return <div>{this.state.descripcion.titulo} </div>;
  }
}

function proyectoToObject(xml) {
  let proyecto = {};
  let parser = new DOMParser();
  let xmlDoc = parser.parseFromString(xml, "text/xml");
  let proyectoXml = xmlDoc.getElementsByTagName("proyecto");
  let descripcionXml = xmlDoc.getElementsByTagName("descripcion");
  for (var i = 0; i < descripcionXml[0].childNodes.length; i++) {
    if (descripcionXml[0].childNodes[i].nodeName !== "#text") {
      proyecto[descripcionXml[0].childNodes[i].nodeName] =
        descripcionXml[0].childNodes[i].innerHTML;
    }
  }
  return proyecto;

  /* 
  let parlamentariosXML = xmlDoc.getElementsByTagName("senador");
  let parlamentarios = [];
  for (let parlamentarioXML of parlamentariosXML) {
    let parlamentario = {};
    for (let parlamentarioProp of parlamentarioXML.childNodes) {
      parlamentario[parlamentarioProp.nodeName] = parlamentarioProp.innerHTML;
    }
    parlamentarios.push(parlamentario);
  }
  return parlamentarios;
  */
}
