import React from "react";

export class SearchInput extends React.Component {
  constructor() {
    super();
    this.state = {
      query: ""
    };
  }

  handleSearch = () => {
    console.log("asd");
    this.props.history.push(`proyecto/${this.state.query}`);
  };
  queryChange = evt => {
    this.setState({ query: evt.target.value });
  };

  render() {
    const { handleSearch, placeholder } = this.props;
    return (
      <form>
        <h1>Ingrese el número de boletin</h1>
        <input
          id="site-search"
          type="search"
          placeholder={placeholder}
          value={this.state.query}
          onChange={this.queryChange}
        />
        <input type="submit" value="Search" onClick={this.handleSearch} />
      </form>
    );
  }
}