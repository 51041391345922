import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { MenuBar } from "./components/nav";
import { ListaSenadores } from "./components/parlamentarios";
import { Proyecto } from "./components/proyecto";
import { SearchInput } from "./components/searchproyecto";

import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <Router>
      <div className="App">
        <MenuBar />
        <Switch>
          <Route path="/proyecto" component={Proyecto} />
          <Route path="/senadores" component={ListaSenadores} />
          <Route path="/" component={SearchInput} />
        </Switch>
      </div>
    </Router>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

