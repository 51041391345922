import React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export class ListaSenadores extends React.Component {
  constructor(props) {
    super(props);
    this.state = { senadores: null };
  }
  componentDidMount() {
    const xhr = new XMLHttpRequest();
    const url = "https://congresoapi.herokuapp.com/senadores";
    xhr.open("GET", url);
    xhr.send();
    xhr.onload = () => {
      this.setState({ senadores: parlToObject(xhr.response) });
      // console.log(this.state.senadores);
    };
  }
  render() {
    if (this.state.senadores != null) {
      return (
        <Container>
          <Row><h1 className="title is-1">Senadores</h1></Row>
          <hr />
          <Row>
            {this.state.senadores.map(arrayElementSenador => (
              <Col>
                <Senador
                  key={arrayElementSenador.PARLID}
                  senador={arrayElementSenador}
                />
              </Col>
            ))}
          </Row>
        </Container>
      );
    }
    return "";
  }
}

export function Senador(props) {
  let nombre = `${props.senador.PARLNOMBRE} ${
    props.senador.PARLAPELLIDOPATERNO
    } ${props.senador.PARLAPELLIDOMATERNO}`;
  let region = props.senador.REGION;
  let mail = props.senador.EMAIL;
  let urlCurriculum = props.senador.CURRICULUM;
  return (
    <Card style={{ width: '15rem' }}>
      <Card.Img variant="top" className="senadorimg" src={`https://senado.cl/appsenado/index.php?mo=comisiones&ac=getImage&fid=${
        props.senador.PARLID
        }`} />
      <Card.Body>
        <Card.Title>{nombre}</Card.Title>
        <Card.Text>
          <p>{region}</p>
          <p>{mail}</p>
        </Card.Text>
        <Button href={urlCurriculum} variant="primary">Ver CV</Button>
      </Card.Body>
    </Card >

  );
}

function parlToObject(xml) {
  let parser = new DOMParser();
  let xmlDoc = parser.parseFromString(xml, "text/xml");
  let parlamentariosXML = xmlDoc.getElementsByTagName("senador");
  let parlamentarios = [];
  for (let parlamentarioXML of parlamentariosXML) {
    let parlamentario = {};
    for (let parlamentarioProp of parlamentarioXML.childNodes) {
      parlamentario[parlamentarioProp.nodeName] = parlamentarioProp.innerHTML;
    }
    parlamentarios.push(parlamentario);
  }
  return parlamentarios;
}
